





























import { ElForm } from 'element-ui/types/form'
import { Vue, Component, PropSync, Ref } from 'vue-property-decorator'
@Component
export default class add extends Vue {
  @PropSync('dialogVisible', { type: Boolean }) visible!: boolean;
  @Ref() readonly ruleForm!: ElForm;
  loading = false;
  formLabelAlign = {
    name: '',
    province: '',
    city: '',
    dist: ''
  };

  rules = {
    name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
    province: [{ required: true, message: '请选择省', trigger: 'change' }],
    city: [{ required: true, message: '请选择市', trigger: 'change' }],
    dist: [{ required: true, message: '请选择区', trigger: 'change' }]
  };

  provinceData = [];
  cityData = [];
  distData = [];

  async fetchProvinceData () {
    const res = await this.$Get(this.$Api.pccByParent, {
      code: 'pro'
    })
    this.provinceData = res.data
  }

  async changeProvince (value: string) {
    this.cityData = []
    this.distData = []
    this.formLabelAlign.city = ''
    this.formLabelAlign.dist = ''
    const res = await this.$Get(this.$Api.pccByParent, {
      code: 'city',
      layers: value
    })
    this.cityData = res.data
  }

  async changeCity (value: string) {
    this.distData = []
    const res = await this.$Get(this.$Api.countybyParents, {
      layers: value
    })
    this.distData = res.data
  }

  reSet () {
    this.formLabelAlign = {
      name: '',
      province: '',
      city: '',
      dist: ''
    }
  }

  submit () {
    this.ruleForm.validate(async (valid) => {
      if (valid) {
        this.loading = true
        await this.$Post(this.$Api.createCommunity, this.formLabelAlign, {
          showMessage: true
        })
        this.loading = false
        this.visible = false
        this.reSet()
        this.$emit('success')
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }

  close () {
    this.formLabelAlign = {
      name: '',
      province: '',
      city: '',
      dist: ''
    }
    this.visible = false
  }

  mounted () {
    this.fetchProvinceData()
  }
}
